<template>
  <UModal
    :ui="{
      background: 'bg-transparent dark:bg-transparent',
      shadow: 'shadow-none',
      width: 'w-full sm:max-w-3xl',
      height: 'sm:max-h-[519px] sm:overflow-hidden',
    }"
  >
    <FormDragAndDropContainer
      :allowed-file-types="acceptedFileTypes"
      :disabled="formStatus !== 'idle'"
      @update="handleFileUpload"
      class="flex flex-col flex-1 w-full h-full overflow-hidden"
    >
      <div
        v-show="fileSizeFeedBack.length"
        class="p-2 text-sm border rounded-lg border-error-700 text-error-700"
      >
        <p class="font-semibold">The following files are too large:</p>
        <ul class="list-disc list-inside">
          <li v-for="file in fileSizeFeedBack" :key="file">
            {{ file }}
          </li>
        </ul>
      </div>

      <div
        class="flex flex-col justify-center w-full h-full min-h-0 gap-2 overflow-hidden sm:flex-row"
        v-if="formStatus === 'idle' || formStatus === 'working'"
        v-auto-animate
      >
        <UCard
          class="flex-1"
          v-if="formStatus === 'idle'"
          :ui="{
            body: {
              padding: 'sm:pb-4',
            },
          }"
        >
          <template #header>
            <h3 class="text-sm font-semibold text-gray-800">
              Submit Deals using one of the following methods
            </h3>
          </template>

          <ol class="flex flex-col gap-3 pl-3 list-decimal marker:text-sm">
            <li>
              <FormFileUpload
                :hide-icon="true"
                :accepted-file-types="acceptedFileTypes"
                @update="handleFileUpload"
              />
            </li>
            <div
              class="flex flex-row items-center gap-2 text-gray-500 text-[10px]"
            >
              <div class="flex-1 h-px bg-gray-200" />
              OR
              <div class="flex-1 h-px bg-gray-200" />
            </div>

            <li>
              <FormUploadUrl @update="handleAddUrl" />
            </li>

            <div
              class="flex flex-row items-center gap-2 text-gray-500 text-[10px]"
            >
              <div class="flex-1 h-px bg-gray-200" />
              OR
              <div class="flex-1 h-px bg-gray-200" />
            </div>

            <li>
              <FormUploadDeckUrl @update="handleAddUrl" />
            </li>
          </ol>
        </UCard>

        <UCard
          v-if="formStatus === 'idle' || formStatus === 'working'"
          :ui="{
            base: 'grid grid-cols-1 grid-rows-[auto_1fr_auto] w-full sm:max-w-xs max-h-full overflow-hidden',
            body: {
              base: 'overflow-y-auto h-full sm:pb-0',
            },
          }"
        >
          <template #header>
            <h3 class="text-sm font-semibold">Queued for processing</h3>
          </template>

          <div class="flex flex-col flex-1 gap-2 overflow-y-auto">
            <div
              class="flex flex-row gap-2 overflow-x-auto sm:block sm:overflow-x-hidden"
            >
              <div v-if="queuedForUpload.length === 0">
                <p class="text-sm text-gray-500">
                  No websites or decks added to queue
                </p>
              </div>
              <div
                v-for="item in queuedForUpload"
                :key="item.id"
                class="sm:mb-2"
              >
                <DeckUploadPreview
                  :id="item.id"
                  :text="item.id"
                  :type="item.type"
                  :status="item.status"
                  :is-submitting="formStatus === 'working'"
                  @remove="removeItemFromQueue"
                />
              </div>
            </div>
          </div>

          <template #footer>
            <UButton
              class="w-full"
              block
              variant="solid"
              :label="
                generatePluralOrSingularText(
                  queuedForUpload.length,
                  'Submit Deal',
                  `Submit ${queuedForUpload.length} Deals`
                )
              "
              :loading="formStatus === 'working'"
              :disabled="
                formStatus === 'working' || queuedForUpload.length === 0
              "
              @click="submitFiles()"
            />
          </template>
        </UCard>
      </div>
      <UCard
        class="mx-auto"
        v-if="formStatus === 'done'"
        :ui="{
          base: 'grid grid-cols-1 grid-rows-[1fr_auto] w-full sm:max-w-lg overflow-hidden',
          body: {
            base: 'overflow-y-auto h-full sm:pb-0',
          },
        }"
      >
        <UploadResults
          :parsed-items="parsedItems"
          :failed-items="failedItems"
        />
        <template #footer>
          <div class="flex flex-col gap-2 sm:flex-row">
            <UButton
              class="flex-1"
              block
              variant="solid"
              color="gray"
              label="Close"
              @click="modal.close()"
            />

            <UButton
              class="flex-1"
              block
              variant="solid"
              label="Submit more"
              @click="resetFileUpload()"
            />
          </div>
        </template>
      </UCard>
    </FormDragAndDropContainer>
  </UModal>
</template>

<script setup lang="ts">
const runtimeConfig = useRuntimeConfig();
const { deckmatch } = runtimeConfig.public;

import { generatePluralOrSingularText } from "@/utils/stringUtils";
import { useDeckmatchUserStore } from "~/stores/deckmatchUser";
import { removeProtocol, addProtocolToUrl } from "@/utils/url";
import { generateErrorMessageFromApi } from "@/utils/error";
import type {
  ListItemType,
  Status,
  WebsiteForm,
  FileBehindUrlForm,
  FileType,
  WebsiteLinkToBeParsed,
  FileBehindUrlToBeParsed,
} from "@/types/UploadDeal";
const acceptedFileTypes = ref([
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-powerpoint",
  "application/vnd.apple.keynote",
  "application/x-iwork-keynote-sffkey",
]);
const maxFileSize = ref(104_857_600); // 100MB · Arbitrary max file size of 100
const fileSizeFeedBack = ref<string[]>([]);
const modal = useModal();

const resetFileUpload = () => {
  queuedForUpload.value = [];
  parsedItems.value = [];
  failedItems.value = [];
  formStatus.value = "idle";
};

const queuedForUpload = ref<ListItemType[]>([]);
const parsedItems = ref<ListItemType[]>([]);
const failedItems = ref<(ListItemType & { errorData?: any })[] >([]);

const formStatus = ref<"idle" | "working" | "done">("idle");

const updateQueuedForUpload = (newList: ListItemType[]) => {
  queuedForUpload.value = newList;
};

const statusFilter = (list: ListItemType[], status: Status) => {
  return list.filter((item: ListItemType) => item.status === status);
};
const removeItemFromQueue = (identifier: string) => {
  queuedForUpload.value = queuedForUpload.value.filter(
    (item) => item.id !== identifier
  );
};

const handleFileUpload = (files: File[]) => {
  fileSizeFeedBack.value = [];
  // filter out the files that are too large
  const [filesThatAreWithinLimit, filesThatAreTooLarge] =
    partitionFilesBySizeLimit(files, maxFileSize.value);

  if (filesThatAreTooLarge.length > 0) {
    const filesTooLarge = filesThatAreTooLarge.map((file) => file.name);
    fileSizeFeedBack.value = filesTooLarge;
  }

  const listOfFiles = filesThatAreWithinLimit.map((file) => {
    return {
      id: file.name,
      type: "file",
      status: "waiting",
      file,
    };
  }) as FileType[];

  queuedForUpload.value = queuedForUpload.value.concat(listOfFiles);
};

const handleAddUrl = (fields: WebsiteForm | FileBehindUrlForm) => {
  let keyToAdd = {};
  if ("url" in fields) {
    const url = addProtocolToUrl(removeProtocol(fields.url));
    keyToAdd = {
      url,
      type: "website",
      id: url,
    };
  } else {
    const url = addProtocolToUrl(removeProtocol(fields.attachment_url));
    keyToAdd = {
      attachment_url: url,
      attachment_password: fields.attachment_password,
      type: "file_behind_url",
      id: url,
    };
  }
  const itemToAdd = [
    {
      status: "waiting",
      ...keyToAdd,
    },
  ] as (WebsiteLinkToBeParsed | FileBehindUrlToBeParsed)[];
  queuedForUpload.value = queuedForUpload.value.concat(itemToAdd);
};
const user = computed(() => useDeckmatchUserStore().getUserInfo);
const authorization = await useSilentAuth();
const sendUrl = async (item: WebsiteLinkToBeParsed) => {
  try {
    await $fetch("/api/deck/upload/url", {
      method: "POST",
      body: JSON.stringify({
        url: item.url,
        investor_email: user.value.email,
      }),
      headers: {
        "Content-Type": "application/json",
        authorization,
      },
    });
  } catch (error) {
    throw error;
  }
};

const sendFormData = async (item: FormData) => {
  const url = `${deckmatch.baseUrl}/${deckmatch.version}`;
  const endpoint = "workflow-management/trigger-deck-workflow/";

  const query = useDefaultQueries({
    investor_email: user.value.email,
    origin: "app",
  });
  try {
    await $fetch(`${url}/${endpoint}`, {
      method: "POST",
      headers: {
        authorization,
      },
      body: item,
      query,
    });
  } catch (error: any) {
    throw {
      response: {
        ...error.response,
        _data: {
          data: error?.response?._data,
        },
      },
    };
  }
};
const submitFiles = async () => {
  if (!queuedForUpload?.value) return;

  const localCopy = queuedForUpload.value as ListItemType[];

  // Check if the form is filled out
  if (localCopy.length === 0) return;

  formStatus.value = "working";
  for (let i = 0; i < localCopy.length; i++) {
    try {
      if (localCopy[i].type === "website") {
        const item = localCopy[i] as WebsiteLinkToBeParsed;
        await sendUrl(item);
        localCopy[i].status = "parsed";
      } else {
        // if the item is a file object or a file behind  a url
        const formData = new FormData();

        if (localCopy[i].type === "file") {
          // for file object
          const item = localCopy[i] as FileType;
          formData.append("attachment_file", item.file);
        } else {
          // Catch all for files behind a url
          const item = localCopy[i] as FileBehindUrlToBeParsed;

          formData.append("attachment_url", item.attachment_url);
          if (item.attachment_password) {
            formData.append("attachment_password", item.attachment_password);
          }
        }

        await sendFormData(formData);

        localCopy[i].status = "parsed";
      }
      // Update the queued for upload list
      updateQueuedForUpload(localCopy);
    } catch (error: any) {
      const errorData = error?.response?._data?.data
      const statusMessage = generateErrorMessageFromApi(
        errorData?.error_code
      );

      localCopy[i] = {
        ...localCopy[i],
        status: "failed",
        statusMessage,
        errorData
      };
    }
  }

  parsedItems.value = statusFilter(localCopy, "parsed");
  failedItems.value = statusFilter(localCopy, "failed");
  failedItems.value = failedItems.value.concat(
    statusFilter(localCopy, "waiting")
  );
  failedItems.value = failedItems.value.concat(
    statusFilter(localCopy, "parsing")
  );
  setTimeout(() => {
    updateQueuedForUpload([]);
    formStatus.value = "done";

    if (failedItems.value.length === 0) {
      //
    }
  }, 1000);
};
</script>
